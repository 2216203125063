import axios from "axios";
import authHeader from "./auth-header";
require("dotenv").config();

const API_URL = process.env.REACT_APP_URL + "/api/cli/"; //http://localhost:8080/api/cli/";

const getClienteByID = async (id) => {
  const resultado = await axios.get(API_URL + "searchID/" + id, {
    headers: authHeader(),
  });
  return resultado;
};

const putGanancia = async (id, ganancia) => {
  const resultado = await axios.put(
    API_URL + "ganancia/" + id + "/" + ganancia,
    {},
    { headers: authHeader() }
  );
  return resultado;
};

const getClientesAll = async () => {
  const resultado = await axios.get(API_URL + "searchAll/", {
    headers: authHeader(),
  });
  return resultado;
};

const putUbicacion = (id, lat, lng) => {
  return axios.put(
    API_URL + "ubicacion/" + id,
    { lat, lng },
    {
      headers: authHeader(),
    }
  );
};

const putDiasDeVisita = (id, diasdevisita) => {
  return axios.put(
    API_URL + "diasdevisita/" + id,
    { diasdevisita },
    {
      headers: authHeader(),
    }
  );
};

/*
const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};
*/
const ClienteService = {
  getClienteByID,
  putGanancia,
  getClientesAll,
  putUbicacion,
  putDiasDeVisita,
};

export default ClienteService;
